import { ThemeDefinition } from 'vuetify';

const myCustomTheme: ThemeDefinition = {
  dark: false,
  colors: {
    // Primary
    primary: '#EB2626',
    primaryLight: '#E02829',
    primaryHover: '#C71919',
    primaryTransparent: '#FCEBEB',

    // Secondary
    secondary: '#121212',
    secondaryLight: '#3D3737',
    secondaryHover: '#000000',

    // Text
    text: '#333333',
    textLight: '#666666',
    textDark: '#999999',

    // Input
    inputBackground: '#2121211E',
    inputUnderline: '#00000099',

    // Background
    background: '#E7EBF2',
    backgroundLight: '#fAfAfA',

    // Disabled/White
    disabled: '#BBBBBB',
    white: '#FFFFFF',

    // Error/Danger
    danger: '#EA3F3F',
    dangerLight: '#FAE1E1',

    // Success
    success: '#35AA65',
    successLight: '#CEF2DD',

    // Warning
    warning: '#E2BB33',

    // Grey
    grey: '#5c5656',
    darkGrey: '##222121',

    // Status
    status1: '#CC9100', // Preparación de viaje
    status2: '#FF6C18', // Arribo_ala_terminal
    status3: '#35AA65', // Comienzo_carga_material
    status4: '#C71919', // Entrega_de_documentos
    status5: '#4B2E9E', // Descarga en destinatario
    status6: '#116FFF', // enViaje
    status7: '#43507E', // Arribo_al_cliente
    status8: '#7f8a00', // Finalización_de_descarga_destinatario
    status9: '#01A299', // descargaDeMaterial
    status10: '#B77179', // proximoDestino
    status11: '#693785', // calificacion
    status12: '#FF076F', // vuelta
    status13: '#C71919', // Llegada a terminal
    status14: '#C71919', // Viaje a finalizar

    statusPreparing: '#CC9100',
    statusCharging: '#FF6711',
    statusOnTheWay: '#116FFF',
    statusDownload: '#01A299',
    statusReturn: '#693785',
    statusNext: '#ff80c3',
    statusFinished: '#fa5c5c',

    outbound: '#B3193A',
  },
};

export default myCustomTheme;
