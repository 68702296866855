import { RouteRecordRaw } from 'vue-router';

const OperationsChiefChildrenRoutes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'homeOperationsChief',
    component: () => import('@/modules/Coordinator/views/HomeView.vue'),
  },
  {
    path: '/coordinators',
    name: 'CoordinatorsList',
    component: () => import('@/modules/OperationsChief/views/CoordinatorsList.vue'),
  },
  {
    path: '/coordinators/edit/:id',
    name: 'EditCoordinator',
    component: () => import('@/modules/OperationsChief/views/EditCoordinator.vue'),
  },
];

export default OperationsChiefChildrenRoutes;
