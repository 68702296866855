<script setup lang='ts'>

import {
  onMounted, ref, watch,
} from 'vue';
import { OrderDetail, TripDetail } from '@/core/types/TripDetail';

const props = defineProps<{
  trip: TripDetail,
  hiddeTimeline?: boolean,
}>();

const actualState = ref({
  label: '',
  icon: '',
  index: 0,
});

const checkActualState = (trip: TripDetail) => {
  trip.orderDetail.forEach((order: OrderDetail, index) => {
    if (index === 0) {
      actualState.value = {
        index,
        label: `${order.label} ${order?.destination || ''}`,
        icon: order.label,
      };
    } else if (order.currentState) {
      actualState.value = {
        index,
        label: `${order.label} ${order?.destination || ''}`,
        icon: order.label,
      };
    }
  });
};
onMounted(() => {
  checkActualState(props.trip);
});
watch(() => props.trip, (trip) => {
  checkActualState(trip);
});

</script>

<template>
  <div class='my-6'>
    <div class='d-flex justify-start align-start mt-4 mb-5'>
      <img :src='`../illustrations/iconsApp/${actualState.icon}.svg`' class='mt-2'
           style='width: 3rem;'
           alt='icono de estado'>
      <div class='ml-6 mt-3 d-flex flex-column text-start'>
        <p class='body--bold--medium color-text-subtitle'>Estados de viaje:</p>
        <p class='body--medium--large color-text'>{{ actualState.label }}</p>
      </div>
    </div>
    <!-- MOBILE -->
    <div class='status-mobile'>
      <v-timeline v-if="!hiddeTimeline" direction='horizontal' truncate-line='both' align='start' line-color='#BBBBBB' :line-inset='-40'>
        <v-timeline-item v-for='(order, index) in trip?.orderDetail' :key='index'
                         :size='index === actualState.index ? "40" : "14"'
                         dot-color='success'
                         :fill-dot='index <= actualState.index'
                         :icon='index === actualState.index ? "mdi-navigation-variant" : ""'
        >
          <template v-slot:icon>
            <v-icon v-if='actualState.index === index'
                    size='18' color='white' class='icon-navigation'>mdi-near-me
            </v-icon>
          </template>
        </v-timeline-item>
      </v-timeline>
      <v-expansion-panels class='mb-2' v-for='(order, index) in trip?.orderDetail' :key='index'>
        <v-expansion-panel>
          <v-expansion-panel-title class='pa-2' :expand-icon="order?.subStates ? 'mdi-menu-down' : ''" :collapse-icon="order?.subStates ? 'mdi-menu-up' : ''">
              <div cols="auto" class='color-text actual-state w-100' :class="(actualState.index === index) && (actualState.label !== 'Viaje a finalizar' && actualState.label !== 'Finalizar viaje') ? '' : 'd-flex'">
                <v-col class="d-flex">
                  <p>
                    <v-icon v-if='order.isCompleted'
                            size='18' class='status-success mr-1'>mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-if='order.currentState'
                            size='18' class='status-success mr-1'>mdi-truck-fast-outline
                    </v-icon>
                    <v-icon v-if='!order.isCompleted && !order.currentState'
                            size='18'
                            class='status-disabled mr-1'>
                      mdi-progress-clock
                    </v-icon>
                  </p>
                  <p v-if="order.destination" class="ml-2 my-auto">
                    {{ order.label }} {{ order.destination || '' }}
                  </p>
                  <p v-else class="ml-2 my-auto">
                    {{ order.label }}
                  </p>
                </v-col>
                <!-- <v-col class="d-flex">
                  <p>
                    <v-icon v-if='actualState.index > index || (actualState.label === "Viaje a finalizar" || actualState.label === "Finalizar viaje")'
                            size='18' class='status-success mr-1'>mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-if='actualState.index === index && (actualState.label !== "Viaje a finalizar" && actualState.label !== "Finalizar viaje")'
                            size='18' class='status-success mr-1'>mdi-truck-fast-outline
                    </v-icon>
                    <v-icon v-if='actualState.index < index'
                            size='18'
                            class='status-disabled mr-1'>
                      mdi-progress-clock
                    </v-icon>
                  </p>
                  <p class="ml-2 my-auto">
                    {{ order.label }}
                  </p>
                </v-col> -->
                <v-col cols="3" class='actual-state me-auto py-1'>
                  <div v-if="(actualState.index === index) && (actualState.label !== 'Viaje a finalizar' && actualState.label !== 'Finalizar viaje')">
                    <p
                      class='text-right color-text-success body--medium--medium text-no-wrap ml-8'
                      style='line-height: normal'>
                      <v-icon class='current-status-icon'>mdi-circle-small</v-icon>
                      Estado actual
                    </p>
                  </div>
                  <div v-if="(actualState.index !== index && order.dateTime) || (actualState.label === 'Viaje a finalizar' || actualState.label === 'Finalizar viaje')">
                    <p>
                      <span class='color-text-success pb-1 pr-1'>
                        {{ (order.dateTime?.split(' ')[0])?.split('-')
                        .reverse()
                        .join('/')
                        ?.slice(0, 5) || '' }}
                      </span><br>
                      {{ (order.dateTime?.split(' ')[1])?.slice(0, 5) || '' }}
                    </p>
                  </div>
                </v-col>
              </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text
            v-for='(subState, subIndex) in order?.subStates'
            :key='subIndex'
            class='pa-0'
          >
            <v-list class='pa-0' v-if='subState.isOptional'>
              <v-list-item
                v-if='subState.isCompleted && (!order.subStates[index - 1]?.isCompleted || !order.subStates[index + 1].isCompleted)'
                class='text-left color-text'>
                <v-icon v-if='subState.isCompleted'
                        size='18' class='status-success mr-2'>mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-if='!subState.isCompleted'
                  size='18'
                  class='status-disabled mr-2'>
                  mdi-progress-clock
                </v-icon>
                {{ subState.label }} {{ (order.dateTime?.split(' ')[0])?.split('-')
                .reverse()
                .join('/')
                ?.slice(0, 5) || '' }}
              </v-list-item>
            </v-list>
            <v-list v-else-if='order.label !== "Descarga en destinatario"' class='pa-0'>
              <v-list-item vi class='text-left color-text'>
                <v-icon v-if='subState.isCompleted || (order.dateTime && order.label == "Calificación")'
                        size='18' class='status-success mr-2'>mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  size='18'
                  class='status-disabled mr-2'>
                  mdi-progress-clock
                </v-icon>
                {{ subState.label }}
                <v-list-item-title v-if='subState.label == "Calificación del servicio" && order.dateTime' class='ml-9 mt-2 color-text'>
                  <v-icon v-for="index in 5" :key="index" :class="[index <= subState.stars ? 'star-selected' : 'star-no-selected']">
                  mdi-star
                  </v-icon>
                </v-list-item-title>
                <v-list-item-title v-if='subState.label == "Nombre de quien recibió el pedido" && order.dateTime' class='ml-9 mt-2 color-text'>
                  {{ subState.receivedName }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else-if='subState.isSelected && ((subState.label == "Descarga correcta" && !order.subStates[1].isSelected) || subState.label == "Devolución del pedido")' class='pa-0'>
              <v-list-item vi class='text-left color-text'>
                <v-icon size='18' class='status-success mr-2'>mdi-check-circle-outline
                </v-icon>
                {{ subState.label }}
                <v-list-item-title v-if='subState.label == "Devolución del pedido"' class='ml-9 mt-2 color-text'>
                  {{ subState.comment }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- DESKTOP -->
    <v-timeline class='status-desktop' side='end' truncate-line='both' align='start' line-color='#BBBBBB'>
      <v-timeline-item v-for='(order, index) in trip?.orderDetail' :key='index'
                       :size='index === actualState.index ? "40" : "14"'
                       dot-color='success'
                       :fill-dot='index <= actualState.index'
                       :icon='index === actualState.index ? "mdi-navigation-variant" : ""'
      >
        <template v-slot:icon>
          <v-icon v-if='actualState.index === index'
                  size='18' color='white' class='icon-navigation'>mdi-near-me
          </v-icon>
        </template>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title :expand-icon="order?.subStates ? 'mdi-menu-down' : ''" :collapse-icon="order?.subStates ? 'mdi-menu-up' : ''">
              <div class='d-flex justify-space-between w-100'>
                <div class='color-text'>
                  <p>
                    <v-icon v-if='order.isCompleted'
                            size='18' class='status-success mr-4'>mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-if='order.currentState'
                            size='18' class='status-success mr-4'>mdi-truck-fast-outline
                    </v-icon>
                    <v-icon v-if='!order.isCompleted && !order.currentState'
                            size='18'
                            class='status-disabled mr-4'>
                      mdi-progress-clock
                    </v-icon>
                    {{ order?.label }} {{ order?.destination || '' }}
                  </p>
                  <!-- <p>
                    <v-icon v-if='actualState.index > index || (actualState.label === "Viaje a finalizar" || actualState.label === "Finalizar viaje")'
                            size='18' class='status-success mr-4'>mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-if='actualState.index === index && (actualState.label !== "Viaje a finalizar" && actualState.label !== "Finalizar viaje")'
                            size='18' class='status-success mr-4'>mdi-truck-fast-outline
                    </v-icon>
                    <v-icon v-if='actualState.index < index'
                            size='18'
                            class='status-disabled mr-4'>
                      mdi-progress-clock
                    </v-icon>
                    {{ order.label }} {{ order.destination || '' }}
                  </p> -->
                </div>
                <div class='pr-7'>
                  <div v-if="(actualState.index === index) && (actualState.label !== 'Viaje a finalizar' && actualState.label !== 'Finalizar viaje')">
                    <p
                      class='text-right color-text-success body--medium--medium text-no-wrap'
                      style='line-height: normal'>
                      <v-icon class='current-status-icon'>mdi-circle-small</v-icon>
                      Estado actual
                    </p>
                  </div>
                  <div v-if="(actualState.index !== index && order.dateTime) || (actualState.label === 'Viaje a finalizar'|| actualState.label === 'Finalizar viaje')">
                    <p class='color-text-success pb-1 pr-1'>
                      {{ (order.dateTime?.split(' ')[0])?.split('-')
                      .reverse()
                      .join('/')
                      ?.slice(0, 5) || ''  }}
                    </p>
                    <p>
                      {{ (order.dateTime?.split(' ')[1])?.slice(0, 5) || '' }}
                    </p>
                  </div>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              v-for='(subState, subIndex) in order?.subStates'
              :key='subIndex'
              class='pa-0'
            >
              <v-list class='pa-0' v-if='subState.isOptional'>
                <v-list-item
                  v-if='subState.isCompleted && (!order.subStates[index - 1]?.isCompleted || !order.subStates[index + 1].isCompleted)'
                  class='text-left color-text'>
                  <v-icon v-if='subState.isCompleted'
                          size='18' class='status-success mr-2'>mdi-check-circle-outline
                  </v-icon>
                  <v-icon
                    v-if='!subState.isCompleted'
                    size='18'
                    class='status-disabled mr-2'>
                    mdi-progress-clock
                  </v-icon>
                  {{ subState.label }} {{ (order.dateTime?.split(' ')[0])?.split('-')
                  .reverse()
                  .join('/')
                  ?.slice(0, 5) || '' }}
                </v-list-item>
              </v-list>
              <v-list v-else-if='order.label !== "Descarga en destinatario"' class='pa-0'>
                <v-list-item vi class='text-left color-text'>
                  <v-icon v-if='subState.isCompleted && order.label !== "Calificación"'
                          size='18' class='status-success mr-2'>mdi-check-circle-outline
                  </v-icon>
                  <v-icon
                    v-else-if='order.label !== "Calificación"'
                    size='18'
                    class='status-disabled mr-2'>
                    mdi-progress-clock
                  </v-icon>
                  {{ subState.label }}
                  <v-list-item-title v-if='subState.label == "Calificación del servicio" && order.dateTime' class='mt-2 color-text'>
                    <v-icon v-for="index in 5" :key="index" :class="[index <= subState.stars ? 'star-selected' : 'star-no-selected']">
                    mdi-star
                    </v-icon>
                  </v-list-item-title>
                  <v-list-item-title v-if='subState.label == "Nombre de quien recibió el pedido" && order.dateTime' class='mt-2 color-text'>
                    {{ subState.receivedName }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else-if='subState.isSelected && ((subState.label == "Descarga correcta" && !order.subStates[1].isSelected) || subState.label == "Devolución del pedido")' class='pa-0'>
                <v-list-item vi class='text-left color-text'>
                  <v-icon size='18' class='status-success mr-2'>mdi-check-circle-outline
                  </v-icon>
                  {{ subState.label }}
                  <v-list-item-title v-if='subState.label == "Devolución del pedido"' class='ml-9 mt-2 color-text'>
                    {{ subState.comment }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<style scoped lang='scss'>
@import '../../../../scss/variable';

.current-status-icon {
  width: 0.25rem;
}

.actual-state {
  display: grid;
  align-content: center;
  justify-items: start;
}

.icon-navigation {
  padding: 0.8rem;
  border: 5px white solid;
  border-radius: 50%;
}

.v-expansion-panel-text {
  &__wrapper {
    padding: 0 !important;
  }
}

.container-icon {
  background-color: $primaryTransparent;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryHover;
}

.color-text-subtitle {
  color: $textLight !important;
}

.color-text {
  color: $secondaryLight !important;
}

.color-text-success {
  color: $success !important;
}

.star-no-selected{
  color: #51494921;
}
.star-selected {
  color: #c8b70f;
}

.status-success {
  background-color: $successLight !important;
  color: #0F5132 !important;
  border-radius: 2rem;
  padding: 0.75rem;
}

.status-disabled {
  background-color: $backgroundLight !important;
  color: #AFAFAF !important;
  border-radius: 2rem;
  padding: 0.75rem;
}

.v-timeline-item {
  &__body {
    width: 100% !important;
  }
}

.status-desktop {
  display: grid;
}

.status-mobile {
  display: none;
}

.delivery {
  background-color: #F0FEF6;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .v-expansion-panel--active > .v-expansion-panel-title {
    min-height: auto;
  }
  .status-desktop {
    display: none;
  }
  .status-mobile {
    display: block;
  }
  .container-icon-column {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .container-date-column {
    padding-top: 0;
    text-align: start;
  }
  .v-timeline--horizontal.v-timeline {
    grid-column-gap: 0;
    width: 114.5%;
  }
}
</style>
